import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/layout";
import MediaCoverageSection from "../components/mediaCoverage";
import OrganisersSection from "../components/organisers";
import Picture from "../components/picture";
import YoutubeVideo from "../components/anthemvideo";
import NewsLetter from "../components/newsLetter";
import styles from "./newsDetails.module.scss";

const NewsDetailsSection = props => {
  const newsArray = props.data.allContentfulNews.nodes;
  const contentfulNews = props.data.contentfulNews;
  return (
    <Layout>
      <main className="w-100">
        <section
          className={`section-padding w-100 mb-0 ${styles.newsContainer} mt-0`}
        >
          <div className={styles.newsHero}>
            {props.data && contentfulNews.newsImage && (
              <div
                className={`${styles.pictureContainer} d-flex align-items-center justify-content-center position-relative text-center h-100`}
              >
                <Picture
                  image={contentfulNews.newsImage}
                  className={`${styles.newsBackgroundImage} abs-top-left w-100 h-100`}
                />
                {!contentfulNews.newsVideoUrl && (
                  <div
                    className={`${styles.foregroundImageContainer} position-relative`}
                  >
                    <Picture
                      image={contentfulNews.newsImage}
                      className={`${styles.newsImage} w-100 h-100`}
                    />
                  </div>
                )}
                {contentfulNews.newsVideoUrl && (
                  <div
                    className={`${styles.youtubeVideoContainer} position-relative`}
                  >
                    <YoutubeVideo
                      video={contentfulNews.newsVideoUrl}
                      classname={`abs-top-left w-100 h-100`}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.newsDetailsContainer}>
            <h1 className={`font-weight-bold ${styles.newsTitle}`}>
              {props.data && contentfulNews.title}
            </h1>
            <p className={styles.newsDate}>
              {props.data && contentfulNews.newsDate}
            </p>
            <div className={styles.newsContent}>
              {props.data &&
                contentfulNews.newsDescription &&
                documentToReactComponents(
                  JSON.parse(contentfulNews.newsDescription.raw)
                )}
              {props.data && contentfulNews.newspaperClipping && (
                <Picture
                  image={contentfulNews.newspaperClipping}
                  className={`${styles.newsClippingImage} w-100`}
                />
              )}
            </div>
            <div
              className={`${styles.metaDataAndNewsLetterContainer} d-flex justify-content-between`}
            >
              <div>
                <p className="font-weight-bold font-16">
                  Categories: {props.data && contentfulNews.category}
                </p>
                <p className="font-16">
                  <span className="font-weight-bold">Tags:&nbsp;</span>{" "}
                  {props.data && contentfulNews.tags}
                </p>
              </div>
              <NewsLetter />
            </div>
          </div>
        </section>
      </main>
      <MediaCoverageSection news={newsArray} />
      <OrganisersSection />
    </Layout>
  );
};

export default NewsDetailsSection;
export const query = graphql`
  query($slug: String!) {
    allContentfulNews {
      nodes {
        newsVideoUrl
        category
        newsSlug
        subtitle
        title
        newsDate(formatString: "DD/MMM")
        newsImage {
          fluid {
            src
            srcSet
            srcSetWebp
            srcWebp
            aspectRatio
            sizes
          }
          description
          title
          file {
            url
          }
        }
        sequenceNumber
      }
    }
    contentfulNews(newsSlug: { eq: $slug }) {
      newsDate(formatString: "MMMM DD, YYYY")
      newsSlug
      title
      subtitle
      newsImage {
        fluid {
          src
          srcSet
          srcSetWebp
          srcWebp
          sizes
        }
        file {
          url
          fileName
        }
      }
      newsDescription {
        raw
      }
      sequenceNumber
      newsVideoUrl
      newspaperClipping {
        fluid {
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
        file {
          url
          fileName
        }
      }
      category
      tags
    }
  }
`;
