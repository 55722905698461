import React, { useState } from "react";
import styles from "../templates/newsDetails.module.scss";
import letterIcon from "../assets/ic_letter.svg";
const NewsLetter = () => {
  const [showSubscribeMessage, setShowSubscribeMessage] = useState(false);
  const [subscribeMessage, setSubscribeMessage] = useState("");
  const [subscribeSubMessage, setSubscribeSubMessage] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState(null);
  const handleSubmit = async e => {
    clearSubscriptionValues();
    e.persist();
    e.preventDefault();
    let json = {};

    new FormData(e.target).forEach((value, key) => {
      json[key] = value;
    });
    const data = json;
    var object = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: object,
      redirect: "follow",
    };
    fetch(
      `${process.env.GATSBY_API_BASE_URL}/addNewsLetterSubscription`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (parsedData) {
        if (parsedData.message.includes("Already")) {
          displaySubscribeMessage("exists");
        } else {
          displaySubscribeMessage("success");
        }
      })
      .catch(error => {
        displaySubscribeMessage("error");
      });
  };

  function displaySubscribeMessage(message) {
    if (message === "success") {
      setSubscriptionValue(
        "Thank you!",
        "Your subscription is successfully, Please check your e-mail",
        "success"
      );
    } else if (message === "exists") {
      setSubscriptionValue(
        "Email already in use",
        "Please use a different e-mail id",
        "error"
      );
    } else if (message === "error") {
      setSubscriptionValue(
        "Error while subscribing",
        "Please try after some time",
        "error"
      );
    }
    setShowSubscribeMessage(true);
  }

  function clearSubscriptionValues() {
    setSubscriptionValue("", "", null);
    setShowSubscribeMessage(false);
  }

  function setSubscriptionValue(message, subMessage, status) {
    setSubscribeMessage(message);
    setSubscribeSubMessage(subMessage);
    setSubscribeStatus(status);
  }
  return (
    <div className={styles.newsletter}>
      <div
        className={`${styles.iconMessageContainer} d-flex justify-content-between align-items-center align-items-sm-end`}
      >
        <div className={styles.iconNameContainer}>
          <img
            src={letterIcon}
            className={`${styles.letter} mb-2`}
            alt="Letter"
          />
          <p className="text-uppercase font-weight-bold mb-0">newsletter</p>
        </div>
        <div className={styles.message}>
          <span>
            Enter your e-mail address to for free registration for Events
          </span>
        </div>
      </div>
      <div className="mt-4">
        <form
          className={styles.subscriptionForm}
          id="newsletterSubscribeForm"
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your e-mail address"
            className="w-100"
            required
          ></input>
          <button
            type="submit"
            className={`btn button-8BC540 ${styles.subscribeButton} font-16 letter-spacing-019 text-uppercase`}
          >
            Subscribe
          </button>
        </form>
      </div>
      {showSubscribeMessage && (
        <div className={`text-center ${styles.subscribeContainer}`}>
          <h4
            className={`${
              subscribeStatus === "error" ? "text-FF0000" : "text-8BC540"
            } ${styles.subscribeTitle}`}
          >
            {subscribeMessage}
          </h4>
          <p className={`${styles.subscribeSubtitle} mb-0`}>
            {subscribeSubMessage}
          </p>
        </div>
      )}
    </div>
  );
};
export default NewsLetter;
